import './wdyr'
import { Fragment, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { store } from './store/store'
// import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './style/index.scss'
import App from './containers/App'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_PUB_KEY,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  release: import.meta.env.VITE_SENTRY_RELEASE_VERSION || 'unknown-release',
  debug: false, //process.env.NODE_ENV !== 'production',
  environment: import.meta.env.VITE_SENTRY_MODE || 'localhost',
  enabled: !import.meta.env.DEV,
})

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <Fragment>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <BrowserRouter>
            <App />
          </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    </Fragment>
  )
}
