import React from 'react'
import ReactMarkdown from 'react-markdown'
import changelog from '/CHANGELOG.md?raw'
import Layout from './Layout'

const Changelog = function Changelog() {
  return (
    <Layout>
      <div className='container py-5'>
      <ReactMarkdown>{changelog}</ReactMarkdown>
      </div>
    </Layout>
  )
}

export default Changelog
