import React, { memo } from 'react'

interface Props {
  prompt: React.ReactNode
}

const LinkToMemberships: React.FC<Props> = memo(({ prompt }) => (
  <span>
    {prompt}, subscribe to one of our{' '}
    <a href="/subscription/purchase" target="blank">
      memberships
    </a>{' '}
    now
  </span>
))

export default LinkToMemberships
