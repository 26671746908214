import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const FeaturePromoTooltip: React.FC<IFeaturePromoTooltipProps> = ({
  text = 'Demo tooltip',
  target = 'foobar',
  children,
  disabled = false,
  placement = 'top',
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <div
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <Tooltip
        placement={placement}
        isOpen={!disabled && tooltipOpen}
        target={target}
        fade={true}
      >
        {text}
      </Tooltip>
      <span>{children}</span>
    </div>
  )
}

export default FeaturePromoTooltip
