import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  UncontrolledTooltip,
  Badge,
  Toast,
  ToastBody,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
} from 'reactstrap'
import { Picture } from 'react-responsive-picture'
import cx from 'classnames'

import '../style/index.scss'
import echoesLogo from '../assets/images/echoes-logo-horizontal.png'
import echoesLogo2x from '../assets/images/echoes-logo-horizontal@2x.png'
/** @ts-ignore-line */
import echoesLogoWebp from '../assets/images/echoes-logo-horizontal@2x.webp'
import {
  getMediaHref,
  getSubscriptionOrUserCapabilities,
} from '../core/helpers'
import posthog from '../api/posthog'
import LinkToMemberships from './LinkToMemberships'
import FeaturePromoTooltip from './FeaturePromoTooltip'
import { editLanguages } from '../constants/index'
import { CHANGE_LANG } from '../constants/user.constants'
import { useAppDispatch, useTypedSelector } from '../store/store'
import {
  useGetMyUserQuery,
  useGetMySubscriptionsQuery,
} from '../store/apiSlice'
import {
  setIsAdmin,
  setUserCapabilities,
  toggleMapFullscreen,
  toggleMapVisible,
} from '../store/creatorSlice'
import { clearToken } from '../store/authSlice'

interface LayoutProps {
  children?: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isMembershipUpgradeActive, setIsMembershipUpgradeActive] =
    useState(false) //hide membership toast // TODO: move it to redux

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data: self, refetch: refetchMyUser } = useGetMyUserQuery({})
  const { data: subscriptions, isFetching: isFetchingMySubscriptions } =
    useGetMySubscriptionsQuery({})
  // const { data: profiles, isFetching: isFetchingMyProfiles } = useGetMyProfilesQuery({})

  const mapVisible = useTypedSelector((state) => state.creator.mapVisible)
  const mapFullscreen = useTypedSelector((state) => state.creator.mapFullscreen)
  const lang = useTypedSelector((state) => state.creator.lang)
  const showNewsletterPrompt = useTypedSelector(
    (state) => state.creator.showNewsletterPrompt
  )
  const isAdmin = useMemo(() => self && self.role === 'admin', [self])

  const activeSubscription = useMemo(
    () => subscriptions?.find((sub) => sub.active),
    [subscriptions]
  )
  const subscription = useMemo(
    () => subscriptions && subscriptions.length > 0 && subscriptions[0],
    [subscriptions]
  )
  const subscriptionType = useMemo(
    () => (subscription ? subscription.type : 'none'),
    [subscription]
  )
  const capabilities = useMemo(
    () => getSubscriptionOrUserCapabilities(self, subscriptionType),
    [self, subscriptionType]
  )
  useEffect(() => {
    dispatch(setUserCapabilities(capabilities))
  }, [capabilities])
  useEffect(() => {
    dispatch(setIsAdmin(isAdmin), [isAdmin])
  })

  const profilePhoto = useMemo(
    () =>
      self &&
      (self.media ? getMediaHref(self.media, 'profile-photo') : undefined),
    [self]
  )

  const hasSubscription = useMemo(
    () => activeSubscription !== undefined,
    [activeSubscription]
  )

  const handleClickBrand = () => {
    navigate('/')
  }

  const requestSupport = async () => {
    posthog.capture('requested_support', {
      slug: params?.slug || '',
      collection: params?.slug || '',
    })

    const components: Record<string, string> = {
      email: self?.email ?? '',
      'collection-url': params?.slug ?? '',
    }
    const qs = Object.entries(components)
      .map(
        (entry) =>
          `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`
      )
      .join('&')
    window.open(`${import.meta.env.VITE_WEBSITE_URI}/creator-support?${qs}`)
  }

  const upgradeMembership = () => {
    navigate('/subscription/purchase')
    setIsMembershipUpgradeActive(false)
  }

  const dismissMembershipUpgrade = () => {
    setIsMembershipUpgradeActive(false)
  }

  const handleSelectLanguage = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: CHANGE_LANG, payload: ev.currentTarget.value })
  }

  const handleClickLogout = () => {
    // @TODO: reinstate reset app config
    // resetAppConfig(dispatch)
    dispatch(clearToken())
  }

  const handleToggleMap = () => {
    dispatch(toggleMapVisible())
  }

  const handleToggleMapFullscreen = () => {
    dispatch(toggleMapFullscreen())
  }

  const handleOpenEchoesDocs = () => {
    window.open(
      import.meta.env.VITE_ECHOES_DOCS_URL,
      '_blank',
      'noopener,noreferrer'
    )
  }

  const handleOpenEchoesForum = () => {
    window.open(
      import.meta.env.VITE_ECHOES_FORUM_URL,
      '_blank',
      'noopener,noreferrer'
    )
  }

  const handleOnSubmit = (values) => {
    console.log(values)
  }

  return (
    <div className="h-100 d-flex flex-column">
      <nav className="navbar navbar-expand-md px-3 sticky-top navbar-dark shadow">
        <div className="navbar-brand" onClick={handleClickBrand}>
          <Picture
            sources={[
              {
                srcSet: `${echoesLogo} 1x, ${echoesLogo2x} 2x`,
                type: 'image/png',
              },
              {
                srcSet: `${echoesLogoWebp} 2x`,
                type: 'image/webp',
              },
            ]}
            className="header-logo"
          />
          {hasSubscription && (
            <Badge
              color="accent"
              // size
              className="text-dark fw-light ms-1 align-top"
            >
              {activeSubscription?.type.toUpperCase()}
            </Badge>
          )}
        </div>
        <div className="d-flex">
          <div
            className="btn btn-dark text-white p-3 me-2 d-flex d-sm-none justify-content-center align-items-center"
            aria-label="Toggle map"
            onClick={() => handleToggleMapFullscreen()}
          >
            <i className="bi-map"></i>
          </div>
          <button
            className="navbar-toggler text-white bg-dark p-2 d-flex d-sm-none justify-content-center align-items-center"
            aria-label="Toggle navigation"
            id="navbarToggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainNavbar"
          >
            <i
              className="toggler-icon bi-list m-1"
              style={{ fontSize: 20 }}
            ></i>
          </button>
        </div>
        <div
          id="mainNavbar"
          className="collapse navbar-collapse justify-content-end"
        >
          <ul className="navbar-nav ml-auto align-items-center">
            {isAdmin && (
              <li className="nav-item">
                <a
                  className={cx('nav-link', {
                    active: location.pathname.startsWith('/admin/walks'),
                  })}
                  onClick={() => navigate('/admin/walks')}
                >
                  all walks
                </a>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <a
                  className={cx('nav-link', {
                    active: location.pathname.startsWith(
                      '/admin/subscriptions'
                    ),
                  })}
                  onClick={() => navigate('/admin/subscriptions')}
                >
                  all memberships
                </a>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <a
                  className={cx('nav-link', {
                    active: location.pathname.startsWith('/admin/profiles'),
                  })}
                  onClick={() => navigate('/admin/profiles')}
                >
                  all profiles
                </a>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <a
                  className={cx('nav-link', {
                    active: location.pathname.startsWith('/admin/users'),
                  })}
                  onClick={() => navigate('/admin/users')}
                >
                  users
                </a>
              </li>
            )}

            <li className="nav-item">
              <a
                className={cx('nav-link', {
                  active: location.pathname.startsWith('/walk'),
                })}
                onClick={() => navigate('/walks')}
              >
                my walks
              </a>
            </li>

            <li className="nav-item">
              <a
                className={cx('nav-link', {
                  active: location.pathname.startsWith('/profiles'),
                })}
                onClick={() => navigate('/profiles')}
              >
                profiles
              </a>
            </li>

            <li className="nav-item">
              <a
                className={cx('nav-link d-block d-sm-none', {
                  active: location.pathname.startsWith('/account'),
                })}
                onClick={() => navigate('/account')}
              >
                account
              </a>
            </li>

            {capabilities.changeLanguage && (
              <li className="nav-item">
                <Input
                  type="select"
                  bsSize="sm"
                  value={lang}
                  onChange={handleSelectLanguage}
                  style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: '0 none',
                  }}
                >
                  {editLanguages.map((eL, index) => (
                    <option key={index} value={eL.value}>
                      {eL.label}
                    </option>
                  ))}
                </Input>
              </li>
            )}

            <li className="nav-item">
              <a
                id="navbarMapToggle"
                className="nav-link"
                onClick={() => handleToggleMap()}
              >
                <i className="d-none d-sm-block bi-map"></i>
                <span className="d-block d-sm-none">
                  {mapVisible ? 'hide' : 'show'} map
                </span>
              </a>
              <UncontrolledTooltip placement="bottom" target="navbarMapToggle">
                Toggle map
              </UncontrolledTooltip>
            </li>

            <li
              className={cx('nav-item', {
                'd-none d-sm-block': !mapVisible,
              })}
            >
              <div className="d-sm-none">
                <a
                  id="navbarMapFullscreenToggle"
                  className="nav-link"
                  onClick={() => handleToggleMapFullscreen()}
                >
                  <i
                    className={cx('d-none d-sm-block', {
                      'bi-fullscreen-exit': mapFullscreen,
                      'bi-fullscreen': !mapFullscreen,
                    })}
                  ></i>
                  <span className="d-block d-sm-none">
                    {mapFullscreen ? 'exit' : 'show'} fullscreen map
                  </span>
                </a>
                <UncontrolledTooltip
                  placement="bottom"
                  target="navbarMapFullscreenToggle"
                >
                  Fullscreen map
                </UncontrolledTooltip>
              </div>
            </li>

            <UncontrolledDropdown className="nav-item dropdown d-none d-sm-block">
              <DropdownToggle nav className="d-flex">
                {profilePhoto ? (
                  <img src={profilePhoto} className="avatar avatar-xs" alt="" />
                ) : (
                  <>
                    <i
                      className={cx(
                        'd-none d-sm-block',
                        'echoes-icon echoes-icon-xs echoes-icon-icon-edit-profile'
                      )}
                    ></i>
                    <span className="d-block d-sm-none">edit profile</span>
                  </>
                )}
              </DropdownToggle>

              <DropdownMenu className="bg-dark text-white">
                <DropdownItem>
                  <a className="nav-link" href="/account">
                    account
                  </a>
                </DropdownItem>
                <DropdownItem
                  className="bg-transparent border-top border-secondary pt-2 px-4"
                  style={{ minWidth: 260 }}
                  tag="div"
                >
                  {activeSubscription ? (
                    <span className="text-white">
                      {activeSubscription.type.toUpperCase()} MEMBERSHIP
                    </span>
                  ) : (
                    <Button
                      color="primary"
                      className="w-100 text-uppercase py-2"
                      onClick={() => upgradeMembership()}
                      style={{ fontSize: 12 }}
                    >
                      Upgrade membership
                    </Button>
                  )}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            {/* Support */}
            <li className="nav-item">
              <div className="nav-link">
                <FeaturePromoTooltip
                  target="requestSupportButtonDesktopTooltip"
                  text={
                    <LinkToMemberships
                      prompt={
                        <span>
                          To request dedicated support, check our{' '}
                          <a href="https://echoes.xyz/faq" target="_blank">
                            FAQ
                          </a>
                          , or
                        </span>
                      }
                    />
                  }
                  disabled={hasSubscription}
                  placement="bottom"
                >
                  <button
                    className="btn btn-link"
                    id="requestSupportButtonDesktopTooltip"
                    onClick={() => requestSupport()}
                    disabled={!hasSubscription}
                  >
                    <span className="d-block d-sm-none">request support</span>
                    <i className="d-none d-sm-block bi-envelope"></i>
                  </button>
                  {hasSubscription && (
                    <UncontrolledTooltip
                      placement="bottom"
                      target="requestSupportButtonDesktopTooltip"
                    >
                      Request support
                    </UncontrolledTooltip>
                  )}
                </FeaturePromoTooltip>
              </div>
            </li>

            <li className="nav-item d-block d-sm-none my-2 w-100 px-4">
              {hasSubscription ? (
                <span
                  className="d-block text-center text-white border border-secondary w-100 py-2 text-uppercase"
                  style={{ fontSize: 12 }}
                >
                  {activeSubscription?.type.toUpperCase()} MEMBERSHIP
                </span>
              ) : (
                <Button
                  color="primary"
                  className="w-100 p-2 text-uppercase"
                  style={{ fontSize: 12 }}
                  onClick={upgradeMembership}
                >
                  Upgrade membership
                </Button>
              )}
            </li>

            <li className="nav-item d-none d-sm-block"></li>

            {/* FORUM */}
            <li className="nav-item d-none d-sm-block">
              <a
                id="echoesForumTooltip"
                onClick={handleOpenEchoesForum}
                className="nav-link d-flex"
              >
                <i className="bi-people-fill"></i>
              </a>
              <UncontrolledTooltip
                placement="bottom"
                target="echoesForumTooltip"
              >
                Echoes Forum
              </UncontrolledTooltip>
            </li>

            {/* DOCS */}
            <li className="nav-item d-none d-sm-block">
              <a
                id="echoesDocsTooltip"
                className="nav-link d-flex"
                onClick={handleOpenEchoesDocs}
              >
                <i className="bi-question-circle"></i>
              </a>
              <UncontrolledTooltip
                placement="bottom"
                target="echoesDocsTooltip"
              >
                Echoes Docs
              </UncontrolledTooltip>
            </li>

            {/* LOGOUT */}
            <li className="nav-item">
              <a
                id="logoutButtonTooltip"
                className="nav-link d-flex"
                onClick={() => handleClickLogout()}
              >
                <i
                  className={cx('d-none d-sm-block', 'bi-box-arrow-right')}
                ></i>
                <span className="d-block d-sm-none">sign out</span>
              </a>
              <UncontrolledTooltip
                placement="bottom"
                target="logoutButtonTooltip"
              >
                Log out
              </UncontrolledTooltip>
            </li>
          </ul>
        </div>
      </nav>
      <div className="h-100 flex-grow-1 flex-shrink-1 overflow-auto">
        {children}
      </div>

      <div className="d-flex align-items-center justify-content-between text-light px-3 py-1 small">
        version {import.meta.env.VITE_VERSION} <NavLink to="/changelog">changelog</NavLink>
        {showNewsletterPrompt && (
          <form className="d-flex px-3 py-1 align-items-center" onSubmit={handleOnSubmit}>
            <div className="text-light pe-3">Subscribe to newsletter?</div>
            <div className="">
              <div className="input-group input-group-sm">
                <input
                  type="text"
                  className="form-control"
                  placeholder="email…"
                  aria-label="Username"
                  aria-describedby="emailInputLabel"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="submit"
                  id="button-addon2"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      <Toast
        isOpen={!activeSubscription && isMembershipUpgradeActive}
        className="fixed-bottom m-3 bg-toast-dark px-1 py-1"
        style={{ maxWidth: 470 }}
      >
        <div className="toast-header bg-toast-dark text-white pt-0 pb-2 light-close-icon">
          <strong className="mr-auto"></strong>
          <Button
            color="link"
            onClick={() => dismissMembershipUpgrade()}
            className="close"
          >
            <i
              className="echoes-icon echoes-icon-close"
              style={{ fontSize: '0.4rem' }}
            ></i>
          </Button>
        </div>
        <ToastBody
          className="d-flex justify-content-center align-items-center pt-0 pb-4 mb-2"
          style={{ paddingLeft: 35, paddingRight: 38 }}
        >
          <div className="me-3">
            <i
              className="echoes-icon echoes-icon-flip-vertical echoes-icon-info mb-3 text-white"
              style={{ fontSize: 18 }}
            ></i>
          </div>
          <p className="mb-0 me-3 text-white">
            Unlock a wealth of incredible features with a membership
          </p>
          <Button
            className="py-1 px-4"
            color="primary"
            size="sm"
            onClick={() => upgradeMembership()}
            style={{ fontSize: 12 }}
          >
            UPGRADE
          </Button>
        </ToastBody>
      </Toast>
    </div>
  )
}

export default Layout
